import $ from 'jquery';

$(document).ready(function() {
  $(".custom-file-input").each(function(){
    $(this).next(".custom-file-label").text('Ajouter des pièces jointes');
  });
  $(".custom-file-input").change(function(){
    $(this).next(".custom-file-label").text(this.files[0].name);
  });
  $('.rech-link').click(function(e){
    e.preventDefault();
    $('.center-search').toggleClass('search-open');

  });
  $('.close-search').click(function(e){
    e.preventDefault();
    $('.center-search').removeClass('search-open');

  });


  /** PANIER DEROULANT **/
  $('.link-top-cart').hover(function () {
    $(".panier-deroulant").toggle();
  });
  /** Langue **/
  $('.lang-box').click(function(e){
    e.preventDefault();
    $('ul.langue-list').slideToggle();
  });


  /*** Header sticky **/
  $(function () {
    var msie6 = $.browser == 'msie' && $.browser.version < 7;

    if (!msie6) {
      var top = $('.header-body').offset().top - parseFloat($('.header-body').css('margin-top').replace(/auto/, 0));
      $(window).scroll(function (event) {
        // what the y position of the scroll is
        var y = $(this).scrollTop();

        // whether that's below the form
        if (y > top) {
          // if so, ad the fixed class
          $('.header-body').addClass('headerFixed');
          $('.big-wrapper').addClass('padding-body');
        } else {
          // otherwise remove it
          $('.header-body').removeClass('headerFixed');
          $('.big-wrapper').removeClass('padding-body');
        }
      });
    }
  });

  /********SUB MENU OVERLAY******/
  if ($(window).width() > 992) {

    var navOverlay_ = $('.menu-overlay');
    $('.navItem').hover(function () {
      var $this = $(this);
      if ($this.find('.subMenu').length) {
        $this.find('.navLink').addClass('active');
        $('body').addClass('hover-item');

      }
    }, function () {
      if ($(this).find('.subMenu').length) {
        $(this).find('.navLink').removeClass('active');
        $('body').removeClass('hover-item');

      }
    });
  }
  else
  {
    $(".navItem").each(function() {
      if ($(this).children('.navLink').next('.subMenu').length==1){
        $(this).addClass('has-sub');
        $(this).children(".navLink").click(function(e){
          var parents_nav = $(this).parents('.onglets');
          var thisAnchor2 = $(this);
          e.preventDefault();
          if (thisAnchor2.next('.subMenu').css('display') !== 'none'){
            thisAnchor2.parent('.navItem').removeClass('active');
            thisAnchor2.next('.subMenu').slideUp();

          }
          else{
            parents_nav.find('.subMenu').slideUp();
            parents_nav.find('.navItem').removeClass('active');
            thisAnchor2.siblings('.subMenu').slideDown();
            thisAnchor2.addClass('active');
          }
        });
      }
    });
  }
  /********HOME SLIDER********/
  var swiperhome = new Swiper('.slider-home', {
    pagination: '.swiper-pagination',
    nextButton: '.home-next',
    prevButton: '.home-prev',
    paginationClickable: true,
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: 2500,
    speed: 900,
    effect: 'fade'
  });
  /* cpte slide */

  var nbre_pag= $('.slider-home').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
  if(nbre_pag<=1){
    $('.slider-home').addClass('just-one')
  }

  $(".slider-home").hover(function(){
    swiperhome.stopAutoplay();
  }, function(){
    swiperhome.startAutoplay();
  });

  $('.open-cookies').click(function(e){
    e.preventDefault();
    $('.ch-cookie-consent__category-group').fadeIn();
    $('.loading-overlay-c').fadeIn();

  });
  $('.close-cookies').click(function(e){
    e.preventDefault();
    $('.ch-cookie-consent__category-group').fadeOut();
    $('.loading-overlay-c').fadeOut();

  });

  /************NOUVEAUTE*************/
  var swiper = new Swiper('.nouv-slider', {
    nextButton: '.nouv-next',
    prevButton: '.nouv-prev',
    slidesPerView: 4,
    pagination: '.nouv-pagination',
    paginationClickable: true,
    spaceBetween: 10,
    loop: true,
    autoplay: 6000,
    speed: 900,
    breakpoints: {
      767: {
        slidesPerView: 2,
        spaceBetween: 10
      },
      960: {
        slidesPerView: 2
      },
      1280: {
        slidesPerView: 3
      },
    }

  });

  /** Scroll top Top **/
  $(window).scroll(function () {
    if ($(this).scrollTop() > 600) {
      $('.goToTop').fadeIn();
    } else {
      $('.goToTop').fadeOut();
    }
  });
  $('.goToTop').click(function () {
    $("html, body").animate({
      scrollTop: 0
    }, 600);
    return false;
  });

  /******* MENU TOOGLE *********/

  $('.btn-menu').click(function(e){
    e.preventDefault();
    $('.navigation').toggleClass('open-mob');
    $('.menu-mob-overlay').fadeToggle();
    $('body').toggleClass('no-scrolling');
  });
  $('.close-menu-mob').click(function(e){
    e.preventDefault();
    $('.navigation').removeClass('open-mob');
    $('.menu-mob-overlay').fadeOut();
    $('body').toggleClass('no-scrolling');
  });
  $('.menu-mob-overlay').click(function(e){
    e.preventDefault();
    $('.navigation').removeClass('open-mob');
    $('.menu-mob-overlay').fadeOut();
    $('body').toggleClass('no-scrolling');
  });
  /********MENU FOOTER TOGGLE********/
  $('.toggle-footer').click(function(){
    var thisAnchor = $(this);
    var tab = thisAnchor.parents('.footer');
    if(thisAnchor.next('.footer-link').css('display') !== 'none'){
      thisAnchor.removeClass('open');
      thisAnchor.next('.footer-link').slideUp();
    }
    else{
      tab.find('.footer-link').slideUp();
      tab.find('.toggle-footer').removeClass('open');
      thisAnchor.siblings('.footer-link').slideDown();
      thisAnchor.addClass('open');}
  });

  $('.card-header').click(function(){
    var thisA = $(this);
    var tabA = thisA.parents('#accordion');
    if(thisA.next('.collapse').css('display') !== 'none'){
      thisA.removeClass('open');
      thisA.next('.collapse').slideUp();
    }
    else{
      tabA.find('.collapse').slideUp();
      tabA.find('.card-header').removeClass('open');
      thisA.siblings('.collapse').slideDown();
      thisA.addClass('open');}
  });

  /***WOW****/
  var wow = new WOW(
    {
      boxClass:     'wow',
      animateClass: 'animated',
      offset:       0,
      mobile:       false,
      live:         true,
      callback:     function(box) {
      },
      scrollContainer: null
    }
  );
  if($(window).width()>1024){
    wow.init();
  }

  /*
    Carousel
*/

  parallaxIt();

  /********FILTER TOGGLE********/
  $('.select-f').click(function(){
    var thisAnchor = $(this);
    var tab = thisAnchor.parents('.filter-item');
    if(thisAnchor.next('.filtreContenu').css('display') !== 'none'){
      tab.removeClass('open');
      thisAnchor.next('.filtreContenu').slideUp();
    }
    else{
      tab.find('.filtreContenu').slideUp();
      tab.find('.select-f').removeClass('open');
      thisAnchor.siblings('.filtreContenu').slideDown();
      tab.addClass('open');}
  });
  $('.filter-lib').click(function(){
    var thisAnchor = $(this);
    var tab = thisAnchor.next('.cell-facette');
    tab.find('.content-fitre-categ').slideToggle();
  });

});
// makes the parallax elements
function parallaxIt() {

  // create variables
  var $fwindow = $(window);
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  // on window scroll event
  $fwindow.on('scroll resize', function() {
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  });

  // for each of content parallax element
  $('[data-type="content"]').each(function (index, e) {
    var $contentObj = $(this);
    var fgOffset = parseInt($contentObj.offset().top);
    var yPos;
    var speed = ($contentObj.data('speed') || 1 );

    $fwindow.on('scroll resize', function (){
      yPos = fgOffset - scrollTop / speed;

      $contentObj.css('top', yPos);
    });
  });

  // for each of background parallax element
  $('[data-type="background"]').each(function(){
    var $backgroundObj = $(this);
    var bgOffset = parseInt($backgroundObj.offset().top);
    var yPos;
    var coords;
    var speed = ($backgroundObj.data('speed') || 0 );

    $fwindow.on('scroll resize', function() {
      yPos = - ((scrollTop - bgOffset) / speed);
      coords = '40% '+ yPos + 'px';

      $backgroundObj.css({ backgroundPosition: coords });
    });
  });

  // triggers winodw scroll for refresh
  $fwindow.trigger('scroll');

  /***** Scroll desciption complete ***/
  $('.description-article a').click(function (e) {
    e.preventDefault();
    $("html, body").animate({
      scrollTop: $("#descr-long").offset().top - parseFloat("110px")
    }, 600);
    return false;
  });
  /***** Scroll desciption complete ***/
  $('.scrollToComplete').click(function (e) {
    e.preventDefault();
    $("html, body").animate({
      scrollTop: $(".pour-completer").offset().top - parseFloat("110px")
    }, 600);
    return false;
  });
  /***** Scroll deja vu ***/
  $('.scrollToDejaVu').click(function (e) {
    e.preventDefault();
    $("html, body").animate({
      scrollTop: $(".deja-vu").offset().top - parseFloat("110px")
    }, 600);
    return false;
  });

};

function init_slide(){
  /***********IMAGE SUPP ARTICLE****************/
  var swiper = new Swiper('.art-img-content', {
    slidesPerView: 3,
    spaceBetween: 10,
    paginationClickable: true,
    direction: 'vertical',
    height: '495px',
    breakpoints: {
      1024: {
        slidesPerView: 1,
        pagination: '.swiper-pagination',
        direction: 'horizontal',
        height: null
      },
      320: {
        slidesPerView: 1,
        pagination: '.swiper-pagination',
        direction: 'horizontal',
        height: null
      },
    }
  });
  var nbre_pagds= $('.art-img-content').children('.swiper-pagination').find('.swiper-pagination-bullet').length;
  if(nbre_pagds<=1){
    $('.art-img-content').addClass('just-one')
  }
  else{
    $('.art-img-content').removeClass('just-one')
  }

  $('.swiper-container').each(function(){
    var nbre_pageselect= $(this).children('.swiper-pagination').find('.swiper-pagination-bullet').length;
    if(nbre_pageselect<=1){
      $(this).addClass('just-one')
    }
    else{
      $(this).removeClass('just-one')
    }
  });

  $('.supp-img').each(function(){
    var is_vertical= $(this).find('.swiper-container-horizontal').length;
    if(is_vertical<=1){
      $(this).addClass('just-one')
    }
    else{
      $(this).removeClass('just-one')
    }
  });

}
window.addEventListener("resize", function(){
  init_slide();
});
$(document).ready(function(e) {
  init_slide();
  /* LIGHTBOX*/

});
