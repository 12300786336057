/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

/* eslint-env browser */

const handleProductOptionsChange = function handleProductOptionsChange() {

  document.querySelectorAll('[name*="sylius_add_to_cart[cartItem][variant]"]').forEach((item) => {
    item.addEventListener('change', (event) => {
      let selector = '';
      // console.log(event.target.closest);
      event.target.closest('#sylius-product-adding-to-cart').querySelectorAll('select[data-option]').forEach((element) => {
        const select = element;
        const option = select[select.selectedIndex].value;
        selector += `[data-${select.getAttribute('data-option')}="${option}"]`;
      });

      const priceElement = document.querySelector('#sylius-variants-pricing').querySelector(selector);
      let price = '';
      let originalPrice = '';
      let percentage = '';
      if (priceElement !== null) {
        price = priceElement.getAttribute('data-value');
        originalPrice = priceElement.getAttribute('data-original-value');
        percentage = priceElement.getAttribute('data-percentage');
      }


      if (price !== '') {
        document.querySelector('[data-js-product-price]').innerHTML = price;
        document.querySelector('button[type=submit]').removeAttribute('disabled');
      } else {
        document.querySelector('[data-js-product-price]').innerHTML = document.querySelector('#sylius-variants-pricing').getAttribute('data-unavailable-text');
        document.querySelector('button[type=submit]').setAttribute('disabled', 'disabled');
      }

      if(originalPrice !== ''){
        document.querySelector('[data-js-product-original-price]').innerHTML = originalPrice;

      }else {
        document.querySelector('[data-js-product-original-price]').innerHTML = '';
      }

      if(percentage !== null){

        document.querySelector('[data-js-product-percentage]').innerHTML = percentage + '%';
        document.querySelector('.picto-promo').style.display = 'inline-block';

      }else {

        document.querySelector('.picto-promo').style.display = 'none';
        document.querySelector('[data-js-product-percentage]').innerHTML = '';

      }


    });


  });
};

const handleProductVariantsChange = function handleProductVariantsChange() {
  document.querySelectorAll('[name="sylius_add_to_cart[cartItem][variant]"]').forEach((item) => {
    item.addEventListener('change', (e) => {

      const price = e.target.getAttribute('data-value');
      const originalPrice = e.target.getAttribute('data-original-value');
      const percentage = e.target.getAttribute('data-percentage');
      const stock = e.target.hasAttribute('data-stock');
      if (originalPrice !== '') {
        document.querySelector('[data-js-product-original-price]').innerHTML = originalPrice;
      } else {
        document.querySelector('[data-js-product-original-price]').innerHTML = '';
      }
      if (percentage !== null) {
        document.querySelector('[data-js-product-percentage]').innerHTML = `${percentage}`;
        document.querySelector('.picto-promo').style.display = 'inline-block';
      } else {
        document.querySelector('.picto-promo').style.display = 'none';
        document.querySelector('[data-js-product-percentage]').innerHTML = '';
      }
      if (stock) {
        document.getElementById('enStock').classList.remove('d-none');
        document.getElementById('horsStock').classList.add('d-none');
      } else {
        document.getElementById('enStock').classList.add('d-none');
        document.getElementById('horsStock').classList.remove('d-none');
      }

      document.querySelector('[data-js-product-price]').innerHTML = price;
    });
  });
};

const SyliusVariantsPrices = () => {
  const syliusVariantsPricing = document.getElementById('sylius-variants-pricing') || null;
  const syliusProductVariants = document.getElementById('sylius-product-variants') || null;

  if (syliusVariantsPricing) {
    handleProductOptionsChange();
  } else if (syliusProductVariants) {
    handleProductVariantsChange();
  }
};

export default SyliusVariantsPrices;
