$(function(){
  let ajaxCall;
  $('.mbiz-sylius-search-filters form[name="filters-form"]').on('submit', function(event){

    event.preventDefault();

    const url = $(this).attr('action');
    const data = $(this).serialize();


    if(ajaxCall != undefined){
      ajaxCall.abort();
    }
    ajaxCall = $.ajax({
      type:"GET",
      data: data,
      url: url,
      success: function(response){

        $(".categ-bottom").html($(response).find('.categ-bottom').html());

      }

    })


  })

  $('.mbiz-sylius-search-filters input[type="number"]').on('change', function() {
    $(this).trigger('keyup');
  })


});
